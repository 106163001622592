import React from 'react';
import { Section, Container } from '@components/global';
import styled from 'styled-components';
import ExternalLink from '@common/ExternalLink';

const StyledExternalLink = styled(ExternalLink)`
  color: inherit;
  text-decoration: none;

  &:hover {
    color: ${props => props.theme.color.black.regular};
  }
`;
const Writing = () => (
        <Section id="writing">
          <Container>
              <div>
                  <h1>Writing</h1>
                  <br></br>
                  <p><StyledExternalLink href="http://uxmag.com/articles/long-term-memory-touchscreen-interaction" target="_blank">Long Term Memory: Touchscreen Interaction</StyledExternalLink> UX Magazine, 2017.</p> 
                  <p><StyledExternalLink href="https://www.amazon.com/Ethnography-Designers-Galen-Cranz/dp/1138121096" target="_blank">Ethnography for Designers</StyledExternalLink>, by Galen Cranz, 201–11. Routledge, 2016.</p>
                  <p><StyledExternalLink href="https://uxirl.com/automotive-ui-design-needs-to-chill-out-962df48c3e49#.9zsx3vp4k" target="_blank">Automotive UI design needs to chill out</StyledExternalLink> UX IRL, 2016.</p>
                  <p><StyledExternalLink href="http://www.shareable.net/blog/7-things-i-learned-from-studying-coworking" target="_blank">7 Things I Learned from Studying Coworking</StyledExternalLink> Shareable, 2014.</p>
                </div>
        </Container>
        </Section>);


export default Writing;



import React from 'react';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';

import Header from '@sections/Header';
import About from '@sections/About';
import Work from '@sections/Work';
import Writing from '@sections/Writing';
import Footer from '@sections/Footer';

const IndexPage = () => (
  <Layout>
    <Navbar />
    <Header />
    <About />
    <Work />
    <Writing />
    <Footer />
  </Layout>
);

export default IndexPage;
